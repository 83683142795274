import {AppUserServices} from "../common/api/appuser.service";

import 
{APPUSERS_SEARCH,
  APPUSER_GET,
  UPDATE_BONUS
} from "./actions/appuser.type";
import {
  SET_APPUSERS,
  SET_APPUSER,
  APPUSERS_RESET,
  APPUSER_RESET,
  SET_APPUSER_PAGINATION,
  SET_APPUSER_ACTIVE
} from "./mutations/appuser.type";
import {CHANGE_PROGRESS} from "./mutations/mutations.type";

const initialState = () => {
  return {
    appUser: {
      id: 0,
      cpf: "",
      name: "",
      birthdate: "",
      phone: "",
      cellphone: "",
      email: "",
      state: "",
      city: "",
      district: "",
      street: "",
      number: 0,
      addressComplement: "",
      zipCode: "",
      balance: 0,
      fazBem: 0,
      lyndusBox: 0
    },
    appUserPagination: {
      search: "",
      itemsPerPage: 10,
      orderBy: "",
      sortDesc: false,
    },
    appUsers: {
      active: true,
      activePage: 1,
      totalPages: 1,
      totalItems: 1,
      items: []
    },
  };
};

const state = initialState();

const getters = {
  appUsers: state => {
    return state.appUsers;
  },
  appUser: state => {
    return state.appUser;
  },
  // appUsersName: state => {
  //   return state.appUsersName;
  // },
  // appUsersCpf: state => {
  //   console.log(state);
  //   return state.appUsersCpf;
  // },
  appUserPagination: state => {
    return state.appUserPagination;
  }
};

const mutations = {
  [SET_APPUSERS](state, data) {
    // Vue.set(state, 'appUsers', [...data]);
    state.appUsers = data;
  },

  [SET_APPUSER_PAGINATION](state, data) {
    state.appUsers.activePage = data.page
    state.appUserPagination.itemsPerPage = data.itemsPerPage
    state.appUserPagination.orderBy = data.orderBy
    state.appUserPagination.sortDesc = data.sortDesc
  },
  [SET_APPUSER_ACTIVE](state, active) {
    state.appUsers.active = active
  },
  
  [APPUSERS_RESET](state) {
    Object.assign(state, initialState().appUsers);
  },
  [SET_APPUSER](state, data) {
    state.appUser = data;
  },
  [APPUSER_RESET](state) {
    Object.assign(state, initialState().appUser);
  }
};

const actions = {
  async [APPUSER_GET]({commit}, id) {
    commit(CHANGE_PROGRESS, true);
    const {data} = await AppUserServices.get(id);
    commit(SET_APPUSER, data);
    commit(CHANGE_PROGRESS, false);
  },

  async [APPUSERS_SEARCH]({commit, state}) {
    // commit(CHANGE_PROGRESS, true)
    const {data} = await AppUserServices.search(
      state.appUsers.activePage,
      state.appUserPagination.itemsPerPage,
      state.appUsers.active,
      state.appUserPagination.search,
      state.appUserPagination.orderBy,
      state.appUserPagination.sortDesc)
    commit(SET_APPUSERS, data)
    // commit(CHANGE_PROGRESS, false)
  },
  
  async [UPDATE_BONUS]({state}, balance) {
    let bonus = {
      id: state.appUser.id,
      balance: balance,
    };

    return AppUserServices.updateBonus(bonus);
  },
  // by name
  // async [GET_APPUSERS_CPF]({ commit }) {
  //   commit(CHANGE_PROGRESS, true);
  //   const { data } = await AppUserServices.listByName(
  //     state.pagination.page,
  //     state.pagination.itemsPerPage
  //   );
  // }
};

export default {
  state,
  getters,
  mutations,
  actions
};

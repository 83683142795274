const productRoute = [
    {
        path: '/products',
        name: 'products',
        component: () => import('@/views/products/Products')
    },
    {
        path: '/product/:id',
        name: 'product',
        component: () => import('@/views/products/Product'),
        props: route => {
            const id = Number.parseInt(route.params.id, 10);
            if (Number.isNaN(id)) {
                return 0;
            }
            return {id};
        }
    },
    {
        path: '/product/:id/edit',
        name: 'editProduct',
        component: () => import('@/views/products/EditCreateProduct'),
        props: route => {
            const id = Number.parseInt(route.params.id, 10);
            if (Number.isNaN(id)) {
                return 0;
            }
            return {id};
        }
    },
    {
        path: '/product-create',
        name: 'productCreate',
        component: () => import('@/views/products/EditCreateProduct')
    },
]

export default productRoute
const employeesRoute = [
  {
    path: "/funcionarios",
    name: "employees",
    component: () => import("@/views/employees/Employees")
  },
  {
    path: "/funcionarios/:id",
    name: "employee",
    component: () => import("@/views/employees/Employee"),
    props: route => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    }
  },
  {
    path: "/funcionarios/create",
    name: "employeesCreate",
    component: () => import("@/views/employees/EmployeesCreate")
  },
  {
    path: "/funcionarios/edit/:id",
    name: "employeeEdit",
    component: () => import("@/views/employees/EmployeesEdit"),
    props: route => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    }
  }
];

export default employeesRoute;

import {BenefitService} from "@/common/api/benefit.service";
import {
  BENEFITS_RESET,
  BENEFIT_RESET,
  SET_BENEFIT,
  SET_BENEFITS,
  SET_BENEFIT_PAGINATION,
  // SET_BENEFIT_ACTIVE,
  BENEFIT_RESET_STATE
} from "./mutations/benefit.type";
import {
  BENEFIT_CREATE,
  BENEFIT_GET,
  BENEFITS_GET,
  BENEFIT_SEARCH,
  // BENEFIT_ACTIVE,
  // BENEFIT_INACTIVE,
  // BENEFIT_EDIT,
  // BENEFIT_EDIT_USERS
} from "./actions/benefit.type";

import {CHANGE_PROGRESS} from "./mutations/mutations.type";

const initialState = () => {
  return {
    benefit: {
      id: 0,
      benefitStatus: "",
      desc: "",
      value: 0.0,
      date: "",
    },
    benefitPagination: {
      search: "",
      itemsPerPage: 10,
      orderBy: "",
      sortDesc: false,
      status: 1,
    },
    benefits: {
      activePage: 1,
      totalPages: 1,
      totalItems: 1,
      items: []
    },
  }
};

export const state = initialState();

const getters = {
  benefits: state => {
    return state.benefits;
  },
  benefitPagination: state => {
    return state.benefitPagination
  },
  benefit: state => {
    return state.benefit;
  }
};

const mutations = {
  [SET_BENEFITS](state, data) {
    state.benefits = data;
  },
  [SET_BENEFIT](state, data) {
    state.benefit = data;
  },
  [SET_BENEFIT_PAGINATION](state, data) {
    state.benefits.activePage = data.page
    state.benefitPagination.itemsPerPage = data.itemsPerPage
    state.benefitPagination.orderBy = data.orderBy
    state.benefitPagination.sortDesc = data.sortDesc
    state.benefitPagination.date = data.date
  },
  [BENEFITS_RESET](state) {
    Object.assign(state, initialState().benefits);
  },
  [BENEFIT_RESET_STATE](state) {
    Object.assign(state, initialState());
  },
  [BENEFIT_RESET](state) {
    Object.assign(state, initialState().benefit);
  }
};

const actions = {
  async [BENEFITS_GET]({commit}) {
    commit(CHANGE_PROGRESS, true);
    const {data} = await BenefitService.list();
    commit(SET_BENEFITS, data);
    
    commit(CHANGE_PROGRESS, false);
  },
  async [BENEFIT_GET]({commit}, id) {
    commit(CHANGE_PROGRESS, true);
    const {data} = await BenefitService.get(id);
    commit(SET_BENEFIT, data);
    
    commit(CHANGE_PROGRESS, false);
  },
  async [BENEFIT_CREATE]({state}) {
    let benefitCreate = {
      desc: state.benefits.desc,
      allEmployees: state.benefits.allEmployees,
      categories: state.benefits.categories
    };
    return BenefitService.create(benefitCreate);
  },
  async [BENEFIT_SEARCH]({commit, state}) {
    // commit(CHANGE_PROGRESS, true)
    const {data} = await BenefitService.search(
      state.benefits.activePage,
      state.benefitPagination.itemsPerPage,
      state.benefitPagination.status,
      state.benefitPagination.search,
      state.benefitPagination.orderBy,
      state.benefitPagination.sortDesc)
    commit(SET_BENEFITS, data)
    // commit(CHANGE_PROGRESS, false)
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};

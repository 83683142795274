const benefitRoute = [
  {
    path: "/benefit",
    name: "benefits",
    component: () => import("@/views/benefits/Benefits")
  },
  {
    path: "/benefit/:id/edit",
    name: "benefitEdit",
    component: () => import("@/views/benefits/BenefitEdit"),
    props: route => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    }
  },
  {
    path: "/benefit/:id",
    name: "benefit",
    component: () => import("@/views/benefits/Benefit"),
    props: route => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    }
  }
  // {
  //   path: "benefit/add",
  //   name: "benefitAdd",
  //   component: () => import("@/views/benefits/BenefitEdit")
  // }
];

export default benefitRoute;

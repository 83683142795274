import ApiService from "@/common/api/api.service";


export const categoryService = {
  list() {
    return ApiService.AuthInstance().get("category");
  },
  get(slug) {
    return ApiService.AuthInstance().get(`category/${slug}`);
  },
  create(slug){
    return ApiService.AuthInstance().post(`category`,{slug});
  },
  update(slug)
  { 
    return ApiService.AuthInstance().put(`category`,slug);
  } 

};

import ApiService from "@/common/api/api.service";

export const ProfileService = {

    get_days_off() {
        ApiService.setHeader()
        return ApiService.get('profile', 'days-off')
    },
    get(slug) {
        ApiService.setHeader()
        return ApiService.get('profile', slug)
    },
    update(slug, data) {
        ApiService.setHeader()
        return ApiService.update('profile', slug, data)
    },
    get_logo(){
        ApiService.setHeader()
        return ApiService.getImageBlob('profile/logo')
    },
    save_logo(file){
        ApiService.setHeader()
        let formData = new FormData();
        formData.append("file", file);
        return ApiService.postFile('profile/logo', formData)
    }
    //post profile/logo form data
    //get profile/log 
    //post profile/days-off
    //get profile/days-off
    //delete profile/days-off/id
}
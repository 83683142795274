import ApiService from "@/common/api/api.service";

export const PersonService = {

    list() {
        ApiService.setHeader()
        return ApiService.get('persons')
    },
    get(slug) {
        ApiService.setHeader()
        return ApiService.get('persons', slug)
    },
    update(slug, data) {
        ApiService.setHeader()
        return ApiService.update('persons', slug, data)
    },
    post(slug, data) {
        ApiService.setHeader()
        return ApiService.post('persons', data)
    },

    // get_logo(){
    //     ApiService.setHeader()
    //     return ApiService.getImageBlob('profile/logo')
    // },
    // save_logo(file){
    //     ApiService.setHeader()
    //     let formData = new FormData();
    //     formData.append("file", file);
    //     return ApiService.postFile('profile/logo', formData)
    // }

 
}
const BeautySpotRoute = [
  {
    path: "/beautyspot",
    name: "bs",
    component: () => import("@/views/beauty-spot/Bs")
  },
  {
    path: "/beautyspot/create",
    name: "bscreate",
    component: () => import("@/views/beauty-spot/BsCreate")
  },
  {
    path: "/beautyspot/:id",
    name: "beautyspot",
    component: () => import("@/views/beauty-spot/BeautySpot"),
    props: route => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    }
  },
  {
    path: "/beautyspot/:id/edit",
    name: "beautySpotEdit",
    component: () => import("@/views/beauty-spot/BsCreate"),
    props: route => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    }
  },
  {
    path: "/bs_finance",
    name: "bsFinance",
    component: () => import("@/views/beauty-spot/finance/Bs")
  },
  {
    path: "/bs_finance/:id",
    name: "bsFinanceView",
    component: () => import("@/views/beauty-spot/finance/Payment"),
    props: route => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    }
  },
];

export default BeautySpotRoute;

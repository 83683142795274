import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'
import pt from 'vuetify/lib/locale/pt'

Vue.use(Vuetify);
export default new Vuetify({
    theme: {
        light: {
            primary: colors.purple,
            secondary: colors.grey.darken1,
            accent: colors.shades.black,
            error: colors.red.accent3,
            anchor: '#ff0000',
            darken2: colors.blue,
            lighten1: colors.blue,
            lighten2: colors.blue,
            lighten3: colors.blue,
            lighten4: colors.blue,
        },
    },
    lang: {
        locales: {pt},
        current: 'pt'
    }
});
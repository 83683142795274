import { categoryService } from "@/common/api/category.service";
import {
  CATEGORY_CREATE,
  CATEGORY_EDIT,
  // CATEGORY_DELETE,
  CATEGORIES_GET,
  CATEGORY_GET
} from "./actions/categories.type";

import {
  SET_CATEGORY,
  RESET_STATE,
  CATEGORIES_RESET_STATE,
  CATEGORY_RESET_STATE,
  SET_CATEGORIES
} from "./mutations/categories.type";

import { CHANGE_PROGRESS } from "./mutations/mutations.type";

const initialState = () => {
  return {
    category: {
      id: 0, clientID: 0, name: '', value: 0
    },
    categories: [
      {
        id: 0,
        name: "",
        value: 0
      }
    ]
  };
};

export const state = initialState();

const getters = {
  categories: state => {
    console.log("State",state.categories);
    return state.categories;
  },
  category: state => {
    console.log("State",state.category);
    return state.category;
  }
};

export const mutations = {
  [SET_CATEGORIES](state, data) {
    state.categories = data;
  },
  [SET_CATEGORY](state, data) {
    state.category = data;
  },
  [CATEGORIES_RESET_STATE](state) {
    Object.assign(state, initialState().categories);
    console.log(state);
  },
  [CATEGORY_RESET_STATE](state) {
    Object.assign(state, initialState().category);
  },
  [RESET_STATE](state) {
    Object.assign(state, initialState());
  }

};

export const actions = {
  async [CATEGORIES_GET]({ commit }) {
    commit(CHANGE_PROGRESS, true);
    const { data } = await categoryService.list();
    commit(SET_CATEGORIES, data);
    commit(CHANGE_PROGRESS, false);
  },
  async [CATEGORY_EDIT]({ commit },slug) {
    commit(CHANGE_PROGRESS, true);
    const { data } = await categoryService.update(slug);
    commit(SET_CATEGORY, data);
    commit(CHANGE_PROGRESS, false);
  },
  async [CATEGORY_CREATE]({ commit },slug) {
    commit(CHANGE_PROGRESS, true);
    const { data } = await categoryService.create(slug);
    commit(SET_CATEGORY, data);
    commit(CHANGE_PROGRESS, false);
  },
  async [CATEGORY_GET]({ commit },slug) {
    commit(CHANGE_PROGRESS, true);
    const { data } = await categoryService.get(slug);
    console.log(data)
    commit(SET_CATEGORY, data);
    commit(CHANGE_PROGRESS, false);
  }

};

export default {
  state,
  actions,
  mutations,
  getters
};

import { ClientService } from "../common/api/clients.service";
import {
  CLIENT_CREATE,
  CLIENT_GET,
  CLIENT_UPDATE,
  CLIENT_SEARCH, CLIENT_DELETE, CLIENT_INACTIVE, CLIENT_ACTIVE
} from "./actions/client.type";
import {
  CLIENTS_SET,
  CLIENT_RESET,
  CLIENTS_RESET,
  CLIENT_SET,
  SET_CLIENT_ACTIVE,
  SET_CLIENT_PAGINATION,
} from "./mutations/client.type";

import { CHANGE_PROGRESS } from "./mutations/mutations.type";

const initialState = () => {
  return {
    
    client: {
      password: "",
      id: 0,
      cnpj: "",
      city: "",
      companyName: "",
      fantasyName: "",
      phone: "",
      email: "",
      state: "",
      district: "",
      street: "",
      number: 0,
      addressComplement: "",
      zipcode: ""
      //weekDays: null
    },

    clientPagination: {
      search: "",
      itemsPerPage: 10,
      orderBy: "",
      sortDesc: false,
    },
    clients: {
      active: true,
      activePage: 1,
      totalPages: 1,
      totalItems: 1,
      items: []
    },
  };
};

export const state = initialState();

export const getters = {
  clients: state => {
    return state.clients;
  },
  client: state => {
    return state.client;
  },
  clientPagination: state => {
    return state.clientPagination;
  }
};

export const mutations = {
  [CLIENTS_SET](state, data) {
    state.clients = data;
  },

  [CLIENT_SET](state, data) {
    state.client = data;
  },

  [SET_CLIENT_PAGINATION](state, data) {
    state.clients.activePage = data.page
    state.clientPagination.itemsPerPage = data.itemsPerPage
    state.clientPagination.orderBy = data.orderBy
    state.clientPagination.sortDesc = data.sortDesc
  },
  [SET_CLIENT_ACTIVE](state, active) {
    state.client.active = active
  },
  [CLIENTS_RESET](state) {
    Object.assign(state, initialState().clients);
  },

  [CLIENT_RESET](state) {
    Object.assign(state, initialState());
  }
};

export const actions = {
  // async [CLIENTS_GET]({ state, commit }) {
  //   commit(CHANGE_PROGRESS, true);
  //   const { data } = await ClientService.list(
  //     state.paginagion.page,
  //     state.paginagion.itemsPerPage
  //   );
  //   commit(CLIENTS_SET, data.items);
  //   let pagination = {
  //     page: data.activePage,
  //     itemsPerPage: state.paginagion.itemsPerPage,
  //     pageStart: 1,
  //     pageStop: data.totalPages,
  //     pageCount: data.totalPages
  //   };
  //   commit(CLIENT_SET_PAGINGATION, pagination);
  //   commit(CLIENTS_SET, data.items);
  //   commit(CHANGE_PROGRESS, false);
  // },


  async [CLIENT_SEARCH]({commit, state}) {
    // commit(CHANGE_PROGRESS, true)
    const {data} = await ClientService.search(
      state.clients.activePage,
      state.clientPagination.itemsPerPage,
      state.clients.active,
      state.clientPagination.search,
      state.clientPagination.orderBy,
      state.clientPagination.sortDesc)
    commit(CLIENTS_SET, data)
    // commit(CHANGE_PROGRESS, false)
  },
  
  async [CLIENT_GET]({ commit }, id) {
    commit(CHANGE_PROGRESS, true);
    const { data } = await ClientService.get(id);
    commit(CLIENT_SET, data);
    commit(CHANGE_PROGRESS, false);
  },

  async [CLIENT_CREATE]({ state }) {
    let clientData = {
      password: state.client.password,
      company: {
        cnpj: state.client.cnpj,
        city: state.client.city,
        companyName: state.client.companyName,
        fantasyName: state.client.fantasyName,
        phone: state.client.phone,
        email: state.client.email,
        state: state.client.state,
        district: state.client.district,
        street: state.client.street,
        number: state.client.number,
        addressComplement: state.client.addressComplement,
        zipcode: state.client.zipcode
      }
    };

    return ClientService.create(clientData);
  },

  async [CLIENT_UPDATE]({ state }) {
    let clientData = {
      id: state.client.id,
      password: state.client.password,
      company: {
        cnpj: state.client.cnpj,
        companyName: state.client.companyName,
        fantasyName: state.client.fantasyName,
        phone: state.client.phone,
        email: state.client.email,
        city: state.client.city,
        state: state.client.state,
        district: state.client.district,
        street: state.client.street,
        number: state.client.number,
        addressComplement: state.client.addressComplement,
        zipcode: state.client.zipcode
      }
    };

    return ClientService.update(clientData);
  },
  [CLIENT_ACTIVE](_, id) {
    return ClientService.active(id)
  },
  [CLIENT_INACTIVE](_, id) {
    return ClientService.inactive(id)
  },
  [CLIENT_DELETE]({state}) {
    return ClientService.delete(state.client.id)
  },
  
};

export default {
  state,
  getters,
  mutations,
  actions
};

const personsRoute = [
    {
        path: "/persons",
        name: "persons",
        component: () => import("@/views/persons/Persons")
    },
    {
        path: "/persons/:id/edit",
        name: "personsEdit",
        component: () => import("@/views/persons/PersonsEdit"),
        props: route => {
            const id = Number.parseInt(route.params.id, 10);
            if (Number.isNaN(id)) {
                return 0;
            }
            return {id};
        }
    },
    {
        path: "/persons/create",
        name: "personCreate",
        component: () => import("@/views/persons/PersonsEdit")
    }
]
export default personsRoute
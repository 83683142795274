import ApiService from "@/common/api/api.service";

export const BenefitService = {
  list() {
    ApiService.setHeader();
    return ApiService.get("benefit");
  },
  search(page=1, itemsPerPage=10, status=0, search="", orderBy = "", sortDesc = "") {
    ApiService.setHeader()
    return ApiService.get(`client/benefit?page=${page}&itemsPerPage=${itemsPerPage}&status=${status}&search=${search}&orderBy=${orderBy}&sortDesc=${sortDesc}`)
  },
  get(slug) {
    ApiService.setHeader();
    return ApiService.get("client/benefit", slug);
  },
  create(data) {
    ApiService.setHeader();
    return ApiService.post("client/benefit", data);
  },
  update(slug, data) {
    ApiService.setHeader();
    return ApiService.update("client/benefit", slug, data);
  },
  sendTicket(id, file){
    console.log(id, file)
    ApiService.setHeader()
    let formData = new FormData();
    formData.append("file", file);
    return ApiService.postFile('client/benefit/'+id+'/sendTicket', formData)
  },
  confirmPayment(id) {
    ApiService.setHeader();
    return ApiService.update(`client/benefit/${id}/confirmPayment`, "");
  },
  updateUser(id, data) {
    ApiService.setHeader();
    return ApiService.update(`client/benefit/${id}/benefit-user`, "", data);
  }
};

import ApiService from "@/common/api/api.service";

export const EmployeeService = {
  list() {
    ApiService.setHeader();
    return ApiService.get("employee");
  },
  get(slug) {
    ApiService.setHeader();
    return ApiService.get("employee", slug);
  },
  create(data) {
    ApiService.setHeader();
    return ApiService.post("employee", data);
  }
  // update(slug, data) {
  //   ApiService.setHeader();
  //   return ApiService.update("benefit", slug, data);
  // }
};

import ApiService from "@/common/api/api.service";

export const ClientService = {
  list(activePage, itemsPerPage) {
    ApiService.setHeader();
    return ApiService.get(
      `client?page=${activePage}&itemsPerPage=${itemsPerPage}`
    );
  },
  search(page=1, itemsPerPage=10, active=true, search="", orderBy = "", sortDesc = "") {
    ApiService.setHeader()
    return ApiService.get(`client?page=${page}&itemsPerPage=${itemsPerPage}&active=${active}&search=${search}&orderBy=${orderBy}&sortDesc=${sortDesc}`)
  },
  get(slug) {
    ApiService.setHeader();
    return ApiService.get("client", slug);
  },
  create(data) {
    ApiService.setHeader();
    return ApiService.post("client", data);
  },
  update(data) {
    ApiService.setHeader();
    return ApiService.put("client", data);
  },

  active(id) {
    ApiService.setHeader()
    return ApiService.update(`client/${id}/active`)
  },
  inactive(id) {
    ApiService.setHeader()
    return ApiService.update(`client/${id}/inactive`)
  },

  delete(id) {
    ApiService.setHeader()
    return ApiService.delete(`client/${id}`)
  }
};

import {WeekDayService} from '@/common/api/week_day.service'
import {
    WEEKDAY_GET,
    WEEKDAYS_GET,
} from './actions/weekday.type'
import {
    SET_WEEKDAY,
    SET_WEEKDAYS,
    WEEKDAY_RESET_STATE
} from './mutations/week_day.type'

import {
    CHANGE_PROGRESS
} from "./mutations/mutations.type"

const initialState = () => {
    return {
        weekDay: {
            id: 0,
            name: "",
        },
        weekDays: []
    }
}

export const state = initialState()

const getters = {
    weekDay: state => {
        return state.weekDay
    },
    weekDays: state => {
        return state.weekDays
    },

}

export const mutations = {
    [SET_WEEKDAY](state, data) {
        state.weekDay = data
    },
    [SET_WEEKDAYS](state, data) {
        state.weekDays = data
    },
    [WEEKDAY_RESET_STATE](state) {
        Object.assign(state, initialState())
    },
}

export const actions = {
    async [WEEKDAY_GET]({commit}, id) {
        commit(CHANGE_PROGRESS, true)
        const {data} = await WeekDayService.get(id)
        commit(SET_WEEKDAY, data)
        commit(CHANGE_PROGRESS, false)
    },
    async [WEEKDAYS_GET]({commit}) {

        commit(CHANGE_PROGRESS, true)
        const {data} = await WeekDayService.list()
        commit(SET_WEEKDAYS, data)
        commit(CHANGE_PROGRESS, false)
    },

    [WEEKDAY_RESET_STATE]({commit}) {
        commit(WEEKDAY_RESET_STATE)
    }
}
export default {
    state,
    actions,
    mutations,
    getters
}

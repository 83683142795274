import ApiService from "@/common/api/api.service";

export const BMFinanceService = {
  listWithdrawalRequests(page = 1, itemsPerPage = 10, active = true, search = "", orderBy = "", sortDesc = "") {
    ApiService.setHeader()
    return ApiService.get(`bm/financial/withdraw?page=${page}&itemsPerPage=${itemsPerPage}&active=${active}&search=${search}&orderBy=${orderBy}&sortDesc=${sortDesc}`)
  },
  getWithdrawalRequestByID(slug) {
    ApiService.setHeader();
    return ApiService.get("bm/financial/withdraw", slug);
  },
  sendConfirmPay(id, file) {
    ApiService.setHeader()
    let formData = new FormData();
    formData.append("file", file);
    return ApiService.postFile(`bm/financial/withdraw/${id}`, formData)
  },
  confirmPayFile(id) {
    ApiService.setHeader();
    return ApiService.getFile(`bm/financial/withdraw/${id}/file`);
  },
}
const ClientRoutes = [
  {
    path: "/clients",
    name: "clients",
    component: () => import("@/views/client/Clients")
  },
  {
    path: "/clients/create",
    name: "clientCreate",
    component: () => import("@/views/client/ClientAdd")
  },
  {
    path: "/clients/:id",
    name: "client",
    component: () => import("@/views/client/ClientId"),
    props: route => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    }
  },
  {
    path: "/clients/:id/edit",
    name: "clientEdit",
    component: () => import("@/views/client/ClientEdit"),
    props: route => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    }
  }
];

export default ClientRoutes;

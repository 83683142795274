const serviceRoutes = [
    {
        path: "/services",
        name: "services",
        component: () => import("@/views/service/Services")
    },
    // {
    //     path: "/services",
    //     name: "services",
    //     component: () => import("@/views/service/Services")
    // },
    {
        path: "/services/:id/edit",
        name: "servicesEdit",
        component: () => import("@/views/service/ServiceEdit"),
        props: route => {
            const id = Number.parseInt(route.params.id, 10);
            if (Number.isNaN(id)) {
                return 0;
            }
            return {id};
        }
    },
    {
        path: "/services/create",
        name: "servicesCreate",
        component: () => import("@/views/service/ServiceEdit"),

    },
    {
        path: "/services/:id?",
        name: "service",
        component: () => import("@/views/service/Service"),
        props: route => {
            const id = Number.parseInt(route.params.id, 10);
            if (Number.isNaN(id)) {
                return 0;
            }
            return {id};
        }
    }
]
export default serviceRoutes
const AppUsers = [
  {
    path: "/app-users",
    name: "appUsers",
    component: () => import("@/views/appuser/AppList")
  },
  {
    path: "/app-users/:id",
    name: "appUser",
    component: () => import("@/views/appuser/AppUser"),
    props: route => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    }
  }
];

export default AppUsers;

import ApiService from "@/common/api/api.service";
import JwtService from "@/common/jwt.service";
import {
  LOGIN,
  LOGOUT,
  REGISTER,
  CHECK_AUTH,
  RESET_PASS,
  UPDATE_PAS
} from "./actions/auth.type";
import { UPDATE_USER } from "./actions/user.type";

import { SET_AUTH, PURGE_AUTH, SET_ERROR } from "./mutations/auth.type";

import { CHANGE_PROGRESS } from "./mutations/mutations.type";

const state = {
  errors: null,
  user: JwtService.jwtUserData(),
  data: JwtService.jwtData(),
  isAuthenticated: JwtService.isValid()
};

const getters = {
  currentUser(state) {
    // returns the username
    return state.data.sub;
  },
  userData(state) {
    return state.data;
  },
  isAuthenticated() {
    return JwtService.isValid();
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(() => {
          context.commit(SET_ERROR, "Usuário ou senha inválidos");
        });
    });
  },
  [UPDATE_PAS](context, credentials) {
    return new Promise(resolve => {
      context.commit(CHANGE_PROGRESS, true);
      ApiService.setHeader();
      ApiService.post("update_pass", credentials)
        .then(({ data }) => {
          context.commit(CHANGE_PROGRESS, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(CHANGE_PROGRESS, false);
          context.commit(SET_ERROR, "Credenciais inválidas");
        });
    });
  },
  [RESET_PASS](context, credentials) {
    context.commit(CHANGE_PROGRESS, true);
    return new Promise(resolve => {
      ApiService.post("reset_pass", credentials)
        .then(({ data }) => {
          context.commit(
            SET_ERROR,
            "Uma nova senha foi enviada no e-mail informado."
          );
          context.commit(CHANGE_PROGRESS, false);
          resolve(data);
        })
        .catch(() => {
          context.commit(CHANGE_PROGRESS, false);
          context.commit(SET_ERROR, "Email inválido ou não registrado.");
        });
    });
  },

  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("users", { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data.user);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [CHECK_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("user")
        .then(({ data }) => {
          context.commit(SET_AUTH, data.user);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = {
      email,
      username,
      bio,
      image
    };
    if (password) {
      user.password = password;
    }

    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data.user);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true;
    state.errors = null;
    JwtService.saveToken(data.accessToken);
    state.data = JwtService.jwtData();
    state.user = JwtService.jwtUserData();
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = null;
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import router from './router'
import store from './store'
import ApiService from './common/api/api.service'
import './registerServiceWorker'
import wb from "@/registerServiceWorker";
Vue.prototype.$workbox = wb;
Vue.config.productionTip = false

ApiService.init()

const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};


Vue.filter('formatDate', function (value) {
    if (!value) return ''
    return new Date(value).toLocaleDateString('pt-br', options)
})

Vue.filter('formatDate0', function (value) {
    if (!value) return ''
    return value.split("-").reverse().join("/")
})

Vue.filter('formatDate2', function (value) {
    if (!value) return ''
    let dt = new Date(value)
    return dt.toLocaleDateString('pt-br') + " " +
        dt.toLocaleTimeString('pt-br', {hour: "numeric", minute: "numeric", hour12: false})
})
Vue.filter('money', function (value) {
    if (!value) value = 0;
    return new Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(value)
})

Vue.filter('formatDate3', function (value) {
  if (!value) return ''
  let dt = new Date(value)
  return dt.toLocaleDateString('pt-br')
})

Vue.filter('formatBoolean', function (value) {
  
  if (value === true) return 'Sim'
  if (value === false) return 'Não'
  
  return ''
})

Vue.directive('uppercase', {
    bind(el, _, vnode) {
        el.addEventListener('keyup', (e) => {
            e.target.value = e.target.value.toUpperCase()
            vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
        })
    }
})

Vue.prototype.$log = console.log

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')

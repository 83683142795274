const recoverPass = [
    {
        path: "/recuperar-senha",
        name: "RecoverPass",
        component: () => import("@/views/auth/PasswordRecover")
    },
    {
        path: "/nova-senha",
        name: "recoverySuccess",
        component: () => import("@/views/auth/RecoveryConfirmed")
    }
];

export default recoverPass;

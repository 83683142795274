import { ProfileService } from "@/common/api/profile.service";
import {
  PROFILE_GET,
  PROFILE_UPDATE,
  // PROFILE_DAYS_OFF_GET,
  PROFILE_LOGO_GET
} from "./actions/profile.type";
import { CHANGE_PROGRESS } from "./mutations/mutations.type";
import {
  SET_PROFILE,
  // SET_DAYS_OFF,
  // PROFILE_ADD_WEEKDAY,
  // PROFILE_REMOVE_WEEKDAY,
  SET_LOGO,
  PROFILE_RESET_STATE
} from "./mutations/profile.type";

const initialState = () => {
  return {
    profile: {
      name: "",
      cnpj: "",
      companyName: "",
      fantasyName: "",
      phone: "",
      email: "",
      state: "",
      city: "",
      district: "",
      street: "",
      number: 0,
      addressComplement: "",
      zipcode: ""
      // weekDays: [],
      // dayOffs: []
    },
    logo: null
  };
};

export const state = initialState();

const getters = {
  profile: state => {
    return state.profile;
  },
  logo: state => {
    return state.logo;
  }
};

const mutations = {
  [SET_PROFILE](state, data) {
    state.profile = data;
  },
  // [SET_DAYS_OFF](state, data) {
  //   state.profile.dayOffs = data;
  // },
  [SET_LOGO](state, data) {
    state.logo = data;
  },
  // [PROFILE_ADD_WEEKDAY](state) {
  //   state.profile.weekDays.push({ id: 0, startTime: null, endTime: null });
  // },
  // [PROFILE_REMOVE_WEEKDAY](state, index) {
  //   state.profile.weekDays.splice(index, 1);
  // },
  [PROFILE_RESET_STATE](state) {
    Object.assign(state, initialState());
  }
};

const actions = {
  async [PROFILE_GET]({ commit }) {
    commit(CHANGE_PROGRESS, true);
    const { data } = await ProfileService.get();
    commit(SET_PROFILE, data);
    commit(CHANGE_PROGRESS, false);
  },
  // async [PROFILE_DAYS_OFF_GET]({ commit }) {
  //   commit(CHANGE_PROGRESS, true);
  //   const { data } = await ProfileService.get_days_off();
  //   commit(SET_DAYS_OFF, data);
  //   commit(CHANGE_PROGRESS, false);
  // },
  async [PROFILE_UPDATE]({ state }) {
    return ProfileService.update("", state.profile);
  },
  async [PROFILE_LOGO_GET]({ commit }) {
    commit(CHANGE_PROGRESS, true);
    const data = await ProfileService.get_logo();
    commit(SET_LOGO, data);
    commit(CHANGE_PROGRESS, false);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};

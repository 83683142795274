import { EmployeeService } from "@/common/api/employee.service";
import {
  EMPLOYEES_GET,
  EMPLOYEE_GET,
  EMPLOYEE_CREATE
} from "./actions/employees.type";
import {
  EMPLOYEES_SET,
  EMPLOYEE_SET,
  EMPLOYEES_RESET,
  EMPLOYEE_RESET
} from "./mutations/employees.type";

import { CHANGE_PROGRESS } from "./mutations/mutations.type";

const initialState = () => {
  return {
    employees: [],
    employee: {
      id: 0,
      name: "",
      cpf: "",
      categories: []
    }
  };
};

const state = initialState();

const getters = {
  employees: state => {
    return state.employees;
  },
  employee: state => {
    console.log("by id", state);
    return state.employee;
  }
};

const mutations = {
  [EMPLOYEES_SET](state, data) {
    state.employees = data;
  },

  [EMPLOYEE_SET](state, data) {
    state.employee = data;
  },

  [EMPLOYEES_RESET](state) {
    Object.assign(state, initialState().employees);
  },

  [EMPLOYEE_RESET](state) {
    Object.assign(state, initialState().employee);
  }
};

const actions = {
  async [EMPLOYEES_GET]({ commit }) {
    commit(CHANGE_PROGRESS, true);
    const { data } = await EmployeeService.list();
    commit(EMPLOYEES_SET, data);
    commit(CHANGE_PROGRESS, false);
  },

  async [EMPLOYEE_GET]({ commit }, id) {
    commit(CHANGE_PROGRESS, true);
    const { data } = await EmployeeService.get(id);
    commit(EMPLOYEE_SET, data);
    console.log("by id", data);
    commit(CHANGE_PROGRESS, false);
  },

  async [EMPLOYEE_CREATE]({ state }) {
    let newEmployee = {
      name: state.employee.name,
      cpf: state.employee.cpf,
      categories: state.employee.categories
    };

    return EmployeeService.create(newEmployee);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};

import ApiService from "@/common/api/api.service";

export const UserService = {

    list_permissions(slug) {
        ApiService.setHeader()
        return ApiService.get('permissions', slug)
    },
    list() {
        ApiService.setHeader()
        return ApiService.get('user')
    },
    busca(slug) {
        ApiService.setHeader()
        return ApiService.get('user', slug)
    },

    get(slug) {
        ApiService.setHeader()
        return ApiService.get('user', slug)
    },
    create(data) {
        ApiService.setHeader()
        return ApiService.post('user', data)
    },
    update(slug, data) {
        ApiService.setHeader()
        return ApiService.update('user', slug, data)
    },
    delete(slug) {
        ApiService.setHeader()
        return ApiService.delete(`user/${slug}`)
    }
}
const bannerRoute = [
    {
        path: '/banners',
        name: 'banners',
        component: () => import('@/views/banner/Banner')
    },
    {
        path: '/banner-create',
        name: 'bannerCreate',
        component: () => import('@/views/banner/BannerCreate')
    }
]

export default bannerRoute
const bmRoutes = [
  {
    path: "/bms",
    name: "bms",
    component: () => import("@/views/bm/BMs")
  },
  {
    path: "/bmsCreate",
    name: "bmCreate",
    component: () => import("@/views/bm/BMEdit")
  },
  {
    path: "/bms/:id",
    name: "bm",
    component: () => import("@/views/bm/BM"),
    props: route => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    }
  },
  {
    path: "/bms/:id/edit",
    name: "bmEdit",
    component: () => import("@/views/bm/BMEdit"),
    props: route => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    }
  },
  {
    path: "/bm_finance",
    name: "bmFinance",
    component: () => import("@/views/bm/finance/Bm")
  },
  {
    path: "/bm_finance/:id",
    name: "bmFinanceView",
    component: () => import("@/views/bm/finance/Payment"),
    props: route => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    }
  }
];

export default bmRoutes;

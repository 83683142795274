import ApiService from "@/common/api/api.service";

export const WeekDayService = {
    list() {
        ApiService.setHeader()
        return ApiService.get('week-days')
    },
    get(slug) {
        ApiService.setHeader()
        return ApiService.get('week-day', slug)
    },
}

import ApiService from "@/common/api/api.service";

export const BeautySpotsService = {
  list(activePage, itemsPerPage) {
    ApiService.setHeader();
    return ApiService.get(`bs?page=${activePage}&itemsPerPage=${itemsPerPage}`);
  },
  search(page=1, itemsPerPage=10, active=true, search="", orderBy = "", sortDesc = "") {
    ApiService.setHeader()
    return ApiService.get(`bs?page=${page}&itemsPerPage=${itemsPerPage}&active=${active}&search=${search}&orderBy=${orderBy}&sortDesc=${sortDesc}`)
  },
  get(slug) {
    ApiService.setHeader();
    return ApiService.get("bs", slug);
  },
  getCep(cep) {
    ApiService.setHeader();
    return ApiService.get(`cep/${cep}`);
  },
  getCategories() {
    ApiService.setHeader();
    return ApiService.get("bs/categories");
  },
  getLogo(slug) {
    ApiService.setHeader();
    return ApiService.get(`bs/${slug}/profile/logo`);
  },
  createLogo(slug, file) {
    ApiService.setHeader();
    let formData = new FormData();
    formData.append("file", file);
    return ApiService.post(`bs/${slug}/profile/logo`, file);
  },
  create(data) {
    ApiService.setHeader();
    return ApiService.post("bs", data);
  },
  update(data) {
    ApiService.setHeader();
    return ApiService.put("bs", data);
  },
  active(id) {
    ApiService.setHeader()
    return ApiService.update(`bs/${id}/active`)
  },
  inactive(id) {
    ApiService.setHeader()
    return ApiService.update(`bs/${id}/inactive`)
  },

  delete(id) {
    ApiService.setHeader()
    return ApiService.delete(`bs/${id}`)
  },

  getPayments(page=1, itemsPerPage=10, active=true, search="", orderBy = "", sortDesc = "") {
    ApiService.setHeader()
    return ApiService.get(`bs/payments?page=${page}&itemsPerPage=${itemsPerPage}&active=${active}&search=${search}&orderBy=${orderBy}&sortDesc=${sortDesc}`)
  },
  getPayment(slug) {
    ApiService.setHeader();
    return ApiService.get("bs/payments", slug);
  },
  sendConfirmPay(id, file){
    ApiService.setHeader()
    let formData = new FormData();
    formData.append("file", file);
    return ApiService.postFile(`bs/payments/${id}`, formData)
  },
  confirmPayFile(id) {
    ApiService.setHeader();
    return ApiService.getFile(`bs/payments/${id}/file`);
  },
};

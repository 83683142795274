const categoriesRoute = [
  {
    path: "/categorias",
    name: "categories",
    component: () => import("@/views/categories/Categories")
  },
  {
    path: "/categoria-edit/:id",
    name: "categoriesEdit",
    component: () => import("@/views/categories/CategoriesEditCreate"),
    props: route => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    }
  },
  {
    path: "/categoria-add",
    name: "categoriesAdd",
    component: () => import("@/views/categories/CategoriesEditCreate")
  }
];

export default categoriesRoute;

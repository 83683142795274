export const CHRANGE_SNACK = 'changeSnack'
export const CHANGE_PROGRESS = 'setProgress'

export const SET_CHART_STATE = 'setChartState'
export const SET_CHART_STATES = 'setChartStates'
export const SET_CHART_CITY = 'setChartCity'
export const SET_CHART_CITIES = 'setChartCities'
export const SET_CHART_DATA = 'setChartData'
export const SET_CHART_COUNTRY_DATA = 'setChartCountryData'
export const SET_CHART_DATA_PREDICTION = 'setChartDataPrediction'


export const SET_INSTITUICOES = 'setInstituicoes'
export const RESET_CHART = 'resetStateStates'



export const SET_FUNCIONARIO = 'setFuncionario'
export const SET_FUNCIONARIOS = 'setFuncionarios'
export const FUNCIONARIO_RESET_STATE = 'funcionarioResetState'


export const SET_MATERIAL = 'setMaterial'
export const SET_MATERIAIS = 'setMateriais'
export const SET_MATERIAL_CONTROLE = 'setMaterialControle'
export const SET_MATERIAIS_CONTROLE = 'setMateriaisContrle'
export const SET_MATERIAL_CONTROLE_MATRIX = 'setMaterialContrleMatrix'
export const SET_MAT_CNTRL_MATRIX_POSITION = 'setMatCntrlMatrixPosition'
export const MATERIAL_RESET_STATE = 'setMaterialResetState'
export const SET_METODO_ID = 'setMetodoID'

export const SET_EXAMES = 'setExames'
export const SET_EXAME_METODOS = 'setDistribuicaoAmostras'

export const SET_RESULTADOS = 'setResultados'

export const SET_AMOSTRA = 'SET_AMOSTRA'
export const SET_AMOSTRAS = 'SET_AMOSTRAS'
export const EXAME_RESET_STATE = 'EXAME_RESET_STATE'

export const SET_PACIENTE = 'setPaciente'
export const SET_PACIENTES = 'setPacientes'
export const SET_SEARCH = 'setSearch'
export const PACIENTE_RESET_STATE = 'pacienteResetState'






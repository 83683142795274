import ApiService from "@/common/api/api.service";

export const AppUserServices = {
  // listByName(activePage, itemsPerPage, name) {
  //   ApiService.setHeader();
  //   return ApiService.get(
  //     `appuser/?page=${activePage}&itemsPerPage=${itemsPerPage}&name=${name}`
  //   );
  // },
  
  search(page=1, itemsPerPage=10, active=true, search="", orderBy = "", sortDesc = "") {
    ApiService.setHeader()
    return ApiService.get(`appuser?page=${page}&itemsPerPage=${itemsPerPage}&active=${active}&search=${search}&orderBy=${orderBy}&sortDesc=${sortDesc}`)
  },
  get(slug) {
    ApiService.setHeader();
    return ApiService.get("appuser", slug);
  },
  updateBonus(bonus) {
    ApiService.setHeader();
    return ApiService.post("appuser/bonus", bonus);
  }
  
  
};

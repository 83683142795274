const ID_TOKEN_KEY = "token";

export const getToken = () => {
    return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
    window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const jwtData = () => {
  const token = getToken();
  if (token) {
    const base64 = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join('')));
  }
  return {};
};

export const jwtUserData = () => {
    if (getToken()) {
        console.log();
        return JSON.parse(atob(getToken().split(".")[1]))["user_claims"];
    }
    return {};
};

export const isValid = () => {
    var current_time = new Date().getTime() / 1000;
    let token = jwtData();
    if (!!getToken() && current_time < token.exp) {
        return true;
    }
    return false;
};

export default {
    getToken,
    saveToken,
    destroyToken,
    jwtData,
    isValid,
    jwtUserData
};

import {PersonService} from "@/common/api/person.service";
import {PERSON_GET, PERSONS_GET, PERSON_CREATE, PERSON_UPDATE} from "./actions/person.type";
import {CHANGE_PROGRESS} from "./mutations/mutations.type"
import {
    SET_PERSON,
    SET_PERSONS,
    PERSON_RESET_STATE
} from "./mutations/person.type";


const initialState = () => {
    return {
        person: {
            name: "",
            cpf: "",
            phone: "",
            birthdate: ""
        },
        persons: []

    };
};

export const state = initialState();

const getters = {
    person: state => {
        return state.person;
    },
    persons: state => {
        return state.persons
    }
};

const mutations = {
    [SET_PERSON](state, data) {
        state.person = data;
    },
    [SET_PERSONS](state, data) {
        state.persons = data;
    },
    [PERSON_RESET_STATE](state) {
        Object.assign(state, initialState());
    }
};

const actions = {
    async [PERSON_GET]({commit}, id) {
        commit(CHANGE_PROGRESS, true);
        const {data} = await PersonService.get(id);
        commit(SET_PERSON, data);
        commit(CHANGE_PROGRESS, false)
    },
    async [PERSONS_GET]({commit}) {
        commit(CHANGE_PROGRESS, true);
        const {data} = await PersonService.list();
        commit(SET_PERSONS, data);
        commit(CHANGE_PROGRESS, false)
    },
    async [PERSON_CREATE]({state}) {
        let person = state.person
        if (person.cpf === ""){
            person.cpf = null
        }
        if (person.birthdate === ""){
            person.birthdate = null
        }

        return PersonService.post("", state.person);
    },
    async [PERSON_UPDATE]({state}) {
        let person = state.person
        if (person.cpf === ""){
            person.cpf = null
        }
        if (person.birthdate === ""){
            person.birthdate = null
        }

        return PersonService.update("", state.person);
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};

import {BeautySpotsService} from "../common/api/beautyspot.service";
import {
  BEAUTY_SPOTS_GET,
  BEAUTY_SPOT_GET,
  BEAUTY_SPOT_LOGO_GET,
  BEAUTY_SPOT_CATEGORIES_GET,
  BEAUTY_SPOT_CREATE,
  BEAUTY_SPOT_UPDATE, BEAUTY_SPOT_SEARCH, BEAUTY_SPOT_DELETE, BEAUTY_SPOT_INACTIVE, BEAUTY_SPOT_ACTIVE
  //BEAUTY_SPOT_LOGO_CREATE
} from "./actions/beautyspots.type";
import {
  BEAUTY_SPOTS_SET,
  BEAUTY_SPOT_SET,
  BEAUTY_SPOTS_RESET,
  BEAUTY_SPOT_RESET,
  BEAUTY_SPOTS_SET_PAGINATION,
  BEAUTY_SPOT_SET_LOGO,
  BEAUTY_SPOT_SET_CATEGORIES,
  BEAUTY_SPOT_RESET_LOGO,
  SET_BEAUTY_SPOT_PAGINATION,
  SET_BEAUTY_SPOT_ACTIVE
} from "./mutations/beautyspots.type";

import {CHANGE_PROGRESS} from "./mutations/mutations.type";


const initialState = () => {
  return {
    bsCategories: [],
    beautySpot: {
      password: "",
      id: 0,
      cnpj: "",
      companyName: "",
      fantasyName: "",
      lat: "",
      lon: "",
      phone: "",
      email: "",
      state: "",
      district: "",
      street: "",
      number: 0,
      addressComplement: "",
      zipcode: "",
      categories: [],
      active: null,
      activeAPP: null,
      planValue: 0,
      planDay: 0,
      balanceDay: 0,
      rateAnticipation: 0,
      rateAnticipationBm: 0
      //weekDays: null
    },
    bsLogo: null,

    beautySpotPagination: {
      search: "",
      itemsPerPage: 10,
      orderBy: "",
      sortDesc: false,
    },
    beautySpots: {
      active: true,
      activePage: 1,
      totalPages: 1,
      totalItems: 1,
      items: []
    },
  };
};

export const state = initialState();

export const getters = {
  beautySpots: state => {
    return state.beautySpots;
  },
  beautySpot: state => {
    return state.beautySpot;
  },
  bsCategories: state => {
    return state.bsCategories;
  },
  beautySpotPagination: state => {
    return state.beautySpotPagination;
  },
  bsLogo: state => {
    return state.bsLogo;
  }
};

export const mutations = {
  [BEAUTY_SPOTS_SET](state, data) {
    state.beautySpots = data;
  },

  [BEAUTY_SPOT_SET](state, data) {
    state.beautySpot = data;
  },

  [BEAUTY_SPOT_SET_CATEGORIES](state, data) {
    state.bsCategories = data;
  },

  [BEAUTY_SPOT_SET_LOGO](state, data) {
    state.bsLogo = data;
  },

  [SET_BEAUTY_SPOT_PAGINATION](state, data) {
    state.beautySpots.activePage = data.page
    state.beautySpotPagination.itemsPerPage = data.itemsPerPage
    state.beautySpotPagination.orderBy = data.orderBy
    state.beautySpotPagination.sortDesc = data.sortDesc
  },
  [SET_BEAUTY_SPOT_ACTIVE](state, active) {
    state.beautySpot.active = active
  },

  [BEAUTY_SPOTS_RESET](state) {
    Object.assign(state, initialState().beautySpots);
  },

  [BEAUTY_SPOT_RESET](state) {
    Object.assign(state, initialState());
  },

  [BEAUTY_SPOT_RESET_LOGO](state) {
    Object.assign(state, initialState().bsLogo);
  }
};

export const actions = {
  async [BEAUTY_SPOTS_GET]({state, commit}) {
    commit(CHANGE_PROGRESS, true);
    const {data} = await BeautySpotsService.list(
      state.pagination.page,
      state.pagination.itemsPerPage
    );
    commit(BEAUTY_SPOTS_SET, data.items);
    let paginationSet = {
      page: data.activePage,
      itemsPerPage: state.pagination.itemsPerPage,
      pageStart: 1,
      pageStop: data.totalPages,
      pageCount: data.totalPages
    };
    commit(BEAUTY_SPOTS_SET_PAGINATION, paginationSet);
    commit(BEAUTY_SPOTS_SET, data.items);
    commit(CHANGE_PROGRESS, false);
  },

  async [BEAUTY_SPOT_SEARCH]({commit, state}) {
    // commit(CHANGE_PROGRESS, true)
    const {data} = await BeautySpotsService.search(
      state.beautySpots.activePage,
      state.beautySpotPagination.itemsPerPage,
      state.beautySpots.active,
      state.beautySpotPagination.search,
      state.beautySpotPagination.orderBy,
      state.beautySpotPagination.sortDesc)
    commit(BEAUTY_SPOTS_SET, data)
    // commit(CHANGE_PROGRESS, false)
  },


  async [BEAUTY_SPOT_GET]({commit}, id) {
    commit(CHANGE_PROGRESS, true);
    const {data} = await BeautySpotsService.get(id);
    commit(BEAUTY_SPOT_SET, data);
    commit(CHANGE_PROGRESS, false);
  },

  async [BEAUTY_SPOT_CATEGORIES_GET]({commit}) {
    commit(CHANGE_PROGRESS, true);
    const {data} = await BeautySpotsService.getCategories();
    commit(BEAUTY_SPOT_SET_CATEGORIES, data);
    commit(CHANGE_PROGRESS, false);
  },

  async [BEAUTY_SPOT_LOGO_GET]({commit}, id) {
    // commit(CHANGE_PROGRESS, true);
    const data = await BeautySpotsService.getLogo(id)
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
      })
      .catch(() => {
        return "";
      });
    commit(BEAUTY_SPOT_SET_LOGO, data);
    // commit(CHANGE_PROGRESS, false);
  },

  async [BEAUTY_SPOT_UPDATE]({state}) {
    let beautySpotData = {
      // password: state.beautySpot.password,
      geo: {
        latitude: state.beautySpot.lat,
        longitude: state.beautySpot.lon
      },
      categories: state.beautySpot.categories,
      id: state.beautySpot.id,
      company: {
        cnpj: state.beautySpot.cnpj,
        companyName: state.beautySpot.companyName,
        fantasyName: state.beautySpot.fantasyName,
        phone: state.beautySpot.phone,
        email: state.beautySpot.email,
        state: state.beautySpot.state,
        city: state.beautySpot.city,
        district: state.beautySpot.district,
        street: state.beautySpot.street,
        number: state.beautySpot.number,
        addressComplement: state.beautySpot.addressComplement,
        zipcode: state.beautySpot.zipcode,
        planValue: state.beautySpot.planValue,
        planDay: state.beautySpot.planDay,
        balanceDay: state.beautySpot.balanceDay,
        rateAnticipation: state.beautySpot.rateAnticipation,
        rateAnticipationBm: state.beautySpot.rateAnticipationBm
        //weekDays: state.beautySpot.weekDays
      }
    };

    if (beautySpotData.categories.length > 0) {
      if (typeof beautySpotData.categories[0] == "object") {
        let categories = [];
        for (let i = 0; i < beautySpotData.categories.length; i++) {
          categories.push(beautySpotData.categories[i].id);
        }
        beautySpotData.categories = categories;
      }
    }

    return BeautySpotsService.update(beautySpotData);
  },
  async [BEAUTY_SPOT_CREATE]({state}) {
    let beautySpotData = {
      password: state.beautySpot.password,
      geo: {
        latitude: state.beautySpot.lat,
        longitude: state.beautySpot.lon
      },
      categories: state.beautySpot.categories,
      company: {
        cnpj: state.beautySpot.cnpj,
        companyName: state.beautySpot.companyName,
        fantasyName: state.beautySpot.fantasyName,
        phone: state.beautySpot.phone,
        email: state.beautySpot.email,
        state: state.beautySpot.state,
        city: state.beautySpot.city,
        district: state.beautySpot.district,
        street: state.beautySpot.street,
        number: state.beautySpot.number,
        addressComplement: state.beautySpot.addressComplement,
        zipcode: state.beautySpot.zipcode,
        planValue: state.beautySpot.planValue,
        planDay: state.beautySpot.planDay,
        balanceDay: state.beautySpot.balanceDay,
        rateAnticipation: state.beautySpot.rateAnticipation,
        rateAnticipationBm: state.beautySpot.rateAnticipationBm
        //weekDays: state.beautySpot.weekDays
      }
    };

    return BeautySpotsService.create(beautySpotData);
  },
  [BEAUTY_SPOT_ACTIVE](_, id) {
    return BeautySpotsService.active(id)
  },
  [BEAUTY_SPOT_INACTIVE](_, id) {
    return BeautySpotsService.inactive(id)
  },
  [BEAUTY_SPOT_DELETE]({state}) {
    return BeautySpotsService.delete(state.beautySpot.id)
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};

const profileRoutes = [
    {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/profile/Profile")
    },
    {
        path: "/profile/edit",
        name: "editProfile",
        component: () => import("@/views/profile/EditProfile")
    }]
export default profileRoutes
import { UserService } from "@/common/api/user.service";
import {
    USER_CREATE,
    USER_EDIT,
    USER_DELETE,
    USER_GET,
    USERS_GET,
    PERMISSIONS_GET
    
    // USER_SEARCH
} from "./actions/user.type.js";

import {
    SET_USER,
    SET_USERS,
    SET_LEVELS,
    SET_PERMISSIONS,
    UPDATE_PERMISSION,
    
    USER_RESET,
    USER_RESET_STATE
} from "./mutations/user.type";

import {
    SET_INSTITUICOES,
    CHANGE_PROGRESS,
} from "./mutations/mutations.type"

const initialState = () => {
    return {
        user: {
            id: 0,
            name: "",
            username: "",
            phone: "",
            email: "",
            password: "",
            permissions: []
        },
        users: [],
        permissions: [],
        levels: [],
        userSearch: {
            search: ""
        }
    };
};

export const state = initialState();

const getters = {
    user: state => {
        return state.user;
    },
    users: state => {
        return state.users;
    },
    userSearch: state => {
        return state.userSearch;
    },
    permissions: state => {
        return state.permissions;
    },
    levels: state => {
        return state.levels;
    },
    checkUsers: state => {
        return state.users.length > 0;
    }
};

export const mutations = {
    [SET_USER](state, data) {
        state.user = data;
    },
    [SET_USERS](state, data) {
        state.users = data;
    },
    [UPDATE_PERMISSION](state, data) {
        state.user.permissions.splice(data.i, 1, data.permission);
    },
    [SET_PERMISSIONS](state, data) {
        state.permissions = data;
    },
    [SET_LEVELS](state, data) {
        state.levels = data;
    },
    [SET_INSTITUICOES](state, data) {
        state.instituicoes = data;
    },
    [USER_RESET](state) {
        Object.assign(state.user, initialState().user);
    },
    [USER_RESET_STATE](state) {
        Object.assign(state, initialState());
    }
};

export const actions = {
    async [PERMISSIONS_GET]({ commit }) {
        commit(CHANGE_PROGRESS, true);
        const { data } = await UserService.list_permissions();
        commit(SET_PERMISSIONS, data.permissions);
        commit(SET_LEVELS, data.levels);

        commit(CHANGE_PROGRESS, false);
    },
    async [USERS_GET]({ commit }) {
        commit(CHANGE_PROGRESS, true);
        // await new Promise(resolve => setTimeout(resolve, 5000))
        const { data } = await UserService.list();
        commit(SET_USERS, data);
        commit(CHANGE_PROGRESS, false);
    },
    async [USER_GET]({ commit }, id) {
        commit(CHANGE_PROGRESS, true);
        const { data } = await UserService.get(id);
        commit(SET_USER, data);
        commit(CHANGE_PROGRESS, false);
    },
    // async [USER_SEARCH]({ commit }) {
    //     commit(CHANGE_PROGRESS, true);
    //     const { data } = await UserService.busca(state.usuarioSearch.search);
    //     commit(SET_USER, data);
    //     commit(CHANGE_PROGRESS, false);
    // },
    [USER_CREATE]({ state }) {
        return UserService.create(state.user);
    },
    [USER_EDIT]({ state }) {
        return UserService.update("", state.user);
    },
    [USER_DELETE]({ state }) {
        return UserService.delete(state.user.id);
    },
    [USER_RESET_STATE]({ commit }) {
        commit(USER_RESET_STATE);
    }
};
export default {
    state,
    actions,
    mutations,
    getters
};

import {BeautySpotsService} from "@/common/api/beautyspot.service";
import {
  BS_FINANCES_GET,
  BS_FINANCE_SEARCH, BS_FINANCE_GET
} from "./actions/bs_finance.type";

import {
  BS_FINANCE_RESET_STATE, SET_BS_FINANCE,
  SET_BS_FINANCE_PAGINATION,
  SET_BS_FINANCES
} from "./mutations/bs_finance.type";

import {CHANGE_PROGRESS} from "./mutations/mutations.type";

const initialState = () => {
  return {
    withdrawalCash: {
      id: 0,
      bsID: 0,
      companyName: "",
      cnpj: "",
      valueReleased: 0,
      valueAnticipation: 0,
      valueRequested: 0,
      totalValue: 0,
      requestDate: "",
      chavePIX: "",
      payDate: null,
      requested: null,
      paid: false
    },

    bsFinancePagination: {
      search: "",
      itemsPerPage: 10,
      orderBy: "",
      sortDesc: false,
    },
    bsFinances: {
      active: true,
      activePage: 1,
      totalPages: 1,
      totalItems: 1,
      items: []
    },
  };
};

export const state = initialState();

const getters = {
  withdrawalCash: state => {
    return state.withdrawalCash
  },
  bsFinancePagination: state => {
    return state.bsFinancePagination;
  },
  bsFinances: state => {
    return state.bsFinances;
  }
};

export const mutations = {
  [SET_BS_FINANCE_PAGINATION](state, data) {
    state.bsFinances.activePage = data.page
    state.bsFinancePagination.itemsPerPage = data.itemsPerPage
    state.bsFinancePagination.orderBy = data.orderBy
    state.bsFinancePagination.sortDesc = data.sortDesc
  },
  [SET_BS_FINANCES](state, data) {

    if (data.items == null) {
      data.items = []
    }

    data.items.sort((a, b) => {
      if (a.paid === b.paid) {
        // If 'paid' is the same, compare by 'requestDate'
        if (a.requestDate === b.requestDate) {
          return 0;
        } else if (a.requestDate < b.requestDate) {
          return -1;
        } else {
          return 1;
        }
      } else {
        // Sort by 'paid' with false first
        if (a.paid === false) {
          return -1;
        } else {
          return 1;
        }
      }
    });

    data.items.forEach((item) => {
        item.valueAnticipation = item.valueAnticipation ? parseFloat(item.valueAnticipation) : 0;
        item.valueReleased = item.valueReleased ? parseFloat(item.valueReleased) : 0;
        item.valueRequested = item.valueRequested ? parseFloat(item.valueRequested) : 0;
        item.valueRequested = item.valueAnticipation + item.valueReleased
      }
    );
    
    state.bsFinances = data;
  },
  [SET_BS_FINANCE](state, data) {
    state.withdrawalCash = data;
  },

  [BS_FINANCE_RESET_STATE](state) {
    Object.assign(state, initialState());
  },


};

export const actions = {
  async [BS_FINANCES_GET]({commit}) {
    commit(CHANGE_PROGRESS, true);
    const {data} = await BeautySpotsService.getPayments();
    commit(SET_BS_FINANCES, data);
    commit(CHANGE_PROGRESS, false);
  },
  async [BS_FINANCE_SEARCH]({commit, state}) {
    // commit(CHANGE_PROGRESS, true)
    const {data} = await BeautySpotsService.getPayments(
      state.bsFinances.activePage,
      state.bsFinancePagination.itemsPerPage,
      state.bsFinances.active,
      state.bsFinancePagination.search,
      state.bsFinancePagination.orderBy,
      state.bsFinancePagination.sortDesc)
    commit(SET_BS_FINANCES, data)
    // commit(CHANGE_PROGRESS, false)
  },

  async [BS_FINANCE_GET]({ commit }, id) {
    commit(CHANGE_PROGRESS, true);
    const { data } = await BeautySpotsService.getPayment(id);
    commit(SET_BS_FINANCE, data);
    commit(CHANGE_PROGRESS, false);
  }
  
};

export default {
  state,
  actions,
  mutations,
  getters
};

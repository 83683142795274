export const BEAUTY_SPOTS_GET = "beautySpotsGet";
export const BEAUTY_SPOT_GET = "beautySpotGet";
export const BEAUTY_SPOT_CREATE = "beautySpotCreate";
export const BEAUTY_SPOT_UPDATE = "beautySpotUpdate";
export const BEAUTY_SPOT_LOGO_GET = "beautySpotLogoGet";
export const BEAUTY_SPOT_LOGO_CREATE = "beautySpotLogoCreate";
export const BEAUTY_SPOT_CATEGORIES_GET = "beautySpotCategoriesGet"
export const BEAUTY_SPOT_SEARCH = "beautySpotSearch"
export const BEAUTY_SPOT_ACTIVE = "beautySpotActive";
export const BEAUTY_SPOT_INACTIVE = "beautySpotInactive";
export const BEAUTY_SPOT_DELETE = "beautySpotDelete";